import React, { useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import CaretDown from "../components/icons/CaretDown"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import trackEvent from "../utils/track-event"

import pictoBefore from "../images/icons/Picto_Vard1.svg"
import pictoDuring from "../images/icons/Picto_Vard2.svg"
import pictoAfter from "../images/icons/Picto_Vard3.svg"
import pictoBody from "../images/icons/Picto_Kropp.svg"

const icons = {
  before: pictoBefore,
  during: pictoDuring,
  after: pictoAfter,
  aboutTheBody: pictoBody,
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: ${props => props.teens ? `rgba(222, 204, 164, 0.3)` : `rgba(${props.bgColor}, 0.15)`};
`

const Content = styled.div`
  max-width: 38rem;
  margin: 0 auto;
  padding: 2.8125rem 0 1rem;

  dl {
    margin: 0;
    padding: 0 1rem;
  }

  dt {
    position: relative;
    padding-left: 1.125rem;
    font: 1rem/1.1 ${props => props.theme.headingFont};
    /* color: rgb(${props => props.textColor}); */
    -webkit-backface-visibility: hidden; /* Prevents arrow icon from flickering on mobile */
    cursor: pointer;

    svg {
      position: absolute;
      top: 0.25rem;
      left: 0;
      transition: transform 0.3s ease;
      transform: rotate(-90deg);
      fill: rgb(${props => props.textColor});
    }

    &.is-expanded svg {
      transform: rotate(0);
    }
  }

  dd {
    margin: 0 0 1.5625rem;
    padding: 0 2.5rem 0 1.125rem;
    overflow: hidden;
    opacity: 0;
    font-size: 0;
    line-height: 1.5;
    color: ${props => props.teens ? "#333" : "black"};
    transition: font-size .3s ease, opacity 0.3s ease, padding 0.3s ease;

    &.is-expanded {
      padding-top: 0.5rem;
      opacity: 1;
      font-size: 1rem;
    }
  }

  @media (min-width: 608px) {
    dt {
      font-size: 1.125rem;
    }

    dd {
      &.is-expanded {
        padding-top: 0.75rem;
      }
    }
  }
`

export default function FaqTemplate({
  data: { allFaqYaml },
  pageContext: { lang, pagePath, category, tag, color },
}) {
  const intl = useIntl()
  const title = intl.formatMessage({ id: category })
  const teens = tag === "tonåringar"
  const [expanded, setExpanded] = useState({})

  const questions = allFaqYaml.nodes.map((question, index) => ({
    ...question,
    id: `q-${index}`,
    idA: `a-${index}`,
    answer: get(question, `answer.html`),
  }))

  const handleClick = (id) => {
    setExpanded(Object.assign({}, expanded, { [id]: !expanded[id] }))
    trackEvent({ category: "FAQ", action: "Expand", name: id })
  }

  const handleKeyDown = (e, id) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault()
      handleClick(id)
    }
  }

  return (
    <Wrapper bgColor={color} teens={teens}>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{
          url: teens ?
            `/assets/share-images/NW_Delning9_13+_${lang === "sv" ? "Sve" : "Eng"}.jpg` :
            `/assets/share-images/NW_Delning6_Fragor_svar_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
          width: 1200,
          height: 630,
        }}
      />
      <Header
        lang={lang}
        title={title}
        increaseTitleContrast={0.45}
        room={teens ? 7 : 4}
        color={`rgb(${color})`}
        roomSymbol={teens ? icons[category] : null}
      />
      <Content textColor={color} teens={teens}>
        <dl>
          {questions.map(({ question, answer, id, idA }) => ([
            <dt
              key={id}
              tabIndex="0"
              onKeyDown={(e) => handleKeyDown(e, id)}
              onClick={() => handleClick(id)}
              className={expanded[id] ? "is-expanded" : ""}
            >
              <CaretDown />
              {question}
            </dt>,
            <dd
              key={idA}
              className={expanded[id] ? "is-expanded" : ""}
              dangerouslySetInnerHTML={{ __html: answer }}
            />,
          ]))}
        </dl>
      </Content>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $category: String, $tag: [String]) {
    allFaqYaml(filter: { lang: { eq: $lang }, category: { eq: $category }, tags: { in: $tag } }, sort: { fields: order }) {
      nodes {
        question
        answer {
          html
        }
      }
    }
  }
`
